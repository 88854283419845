<template>
    <v-container fluid>
        <v-form @submit.prevent="saveEmergencyCall">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                <div class="d-flex">
                    <div class="mb-5">
                        <v-btn color="primary" :to="{name: 'emergency_call.status', params: {status: 'new'}}" class="back-btn" link large>
                            <v-icon>mdi-less-than</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-subheader class="headline">{{ heading }}</v-subheader>
                    </div>
                </div>


                <v-row>
                    <v-col cols="12">
                        <v-card class="mx-auto" outlined >
                            <v-card-text>
                                <div class="text-overline mb-4">
                                    {{$t('who_caused_it')}}
                                </div>
                                <v-row class="pt-2">
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="caller_name" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="caller_name" type="text" :disabled="loading"
                                                          :error="!valid" :error-messages="errors"
                                                          prepend-icon="mdi-card-account-phone-outline"
                                                          :label="$t('caller_name')"
                                                          color="primary"  outlined
                                                          rounded
                                                          dense
                                                          class="rounded-lg"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="caller_phone" rules="required|phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="caller_phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                          :error-messages="errors" :error="!valid"
                                                          :disabled="loading" prepend-icon="mdi-phone"
                                                          :label="$t('caller_phone')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          class="rounded-lg"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="mx-auto" outlined >
                            <v-card-text>
                                <div class="text-overline mb-4">
                                    {{$t('patient_data')}}
                                </div>
                                <v-row class="pt-2">
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="iin" rules="required|numeric|min:12|max:12"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="iin" type="text" :disabled="loading"
                                                          :error="!valid" :error-messages="errors"
                                                          prepend-icon="mdi-numeric"
                                                          :label="$t('iin')"
                                                          outlined
                                                          rounded
                                                          dense
                                                          class="rounded-lg"
                                                          @click="dialogIin = true"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="phone" rules="required|phone"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                          :error-messages="errors" :error="!valid"
                                                          :disabled="loading" prepend-icon="mdi-phone"
                                                          :label="$t('phone')" color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          class="rounded-lg"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="sex" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="sex" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :items="sexItems" item-text="name" item-value="id"
                                                      prepend-icon="mdi-gender-male-female" color="primary"
                                                      :label="$t('sex')"
                                                      outlined
                                                      rounded
                                                      dense
                                                      class="rounded-lg"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="8">
                                        <ValidationProvider ref="name" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="name" type="text" :disabled="loading"
                                                          :error="!valid" :error-messages="errors"
                                                          prepend-icon="mdi-account-heart-outline"
                                                          :label="$t('fio')"
                                                          outlined
                                                          rounded
                                                          dense
                                                          class="rounded-lg"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="4">
                                        <ValidationProvider ref="age" rules="required|numeric|min:1|max:2"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="age" type="text" :disabled="loading"
                                                          :error="!valid" :error-messages="errors"
                                                          prepend-icon="mdi-face-man"
                                                          :label="$t('age')"
                                                          color="primary"
                                                          outlined
                                                          rounded
                                                          dense
                                                          class="rounded-lg"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card class="mx-auto" outlined >
                                            <v-card-text>
                                                <div class="text-overline mb-4">
                                                    {{$t('address')}}
                                                </div>
                                                <v-row class="pt-2">
                                                    <v-col class="py-0" cols="12">
                                                        <ValidationProvider ref="city" rules="required"
                                                                            v-slot="{ errors, valid }">

                                                            <v-select v-model="city" :error="!valid" :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :items="cityItems" item-text="name" item-value="id"
                                                                      prepend-icon="mdi-city-variant" color="primary"
                                                                      :label="$t('city')"
                                                                      outlined
                                                                      rounded
                                                                      dense
                                                                      class="rounded-lg"
                                                                      clearable
                                                                      @change="setCoordinate"
                                                            ></v-select>

                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12">
                                                        <ValidationProvider ref="street" rules="required|min:1|max:255"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="street" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                                                          :label="$t('street')" color="primary"
                                                                          @click="openMap"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          readonly
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="6">
                                                        <ValidationProvider ref="house" rules="required|min:1|max:10"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="house" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-home-floor-1"
                                                                          :label="$t('house')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="6">
                                                        <ValidationProvider ref="apartment" rules="min:1|max:10"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="apartment" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-home-floor-1"
                                                                          :label="$t('apartment')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="6">
                                                        <ValidationProvider ref="entrance" rules="min:1|max:10"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="entrance" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-home-flood"
                                                                          :label="$t('entrance')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="6">
                                                        <ValidationProvider ref="floor" rules="min:1|max:10"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="floor" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-hiking"
                                                                          :label="$t('floor')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="6">
                                                        <ValidationProvider ref="block" rules="min:1|max:10"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="block" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-hospital-building"
                                                                          :label="$t('block')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="6">
                                                        <ValidationProvider ref="housing" rules="min:1|max:10"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="housing" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-page-layout-body"
                                                                          :label="$t('housing')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12">
                                                        <ValidationProvider ref="zip" rules="min:1|max:50"
                                                                            v-slot="{ errors, valid }">
                                                            <v-text-field v-model="zip" type="text"
                                                                          :error="!valid" :error-messages="errors"
                                                                          :disabled="loading" prepend-icon="mdi-map-marker-radius"
                                                                          :label="$t('zip')" color="primary"
                                                                          outlined
                                                                          rounded
                                                                          dense
                                                                          class="rounded-lg"
                                                                          clearable>
                                                            </v-text-field>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card class="mx-auto" outlined >
                                            <v-card-text>
                                                <div class="text-overline mb-4">
                                                    {{$t('patient_category')}}
                                                </div>
                                                <v-row class="pt-2">
                                                    <v-col class="py-0" cols="12" sm="12">
                                                        <ValidationProvider ref="symptom_category" rules="required"
                                                                            v-slot="{ errors, valid }">
                                                            <v-select v-model="symptom_category" :error="!valid" :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :items="symptomCategoryItems" item-text="name" item-value="id"
                                                                      prepend-icon="mdi-family-tree" color="primary"
                                                                      :label="$t('symptom_category')"
                                                                      @change="symptoms=[];getSymptoms()"
                                                                      outlined
                                                                      rounded
                                                                      dense
                                                                      class="rounded-lg"
                                                                      clearable
                                                            ></v-select>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col class="py-0" cols="12" sm="12">
                                                        <ValidationProvider ref="symptoms" rules="required"
                                                                            v-slot="{ errors, valid }">
                                                            <v-select v-model="symptoms" :error="!valid" :error-messages="errors"
                                                                      :disabled="loading"
                                                                      :items="symptomItems"
                                                                      item-text="name"
                                                                      item-value="id"
                                                                      prepend-icon="mdi-sitemap-outline"
                                                                      color="primary"
                                                                      :label="$t('symptoms')"
                                                                      outlined
                                                                      rounded
                                                                      dense
                                                                      class="rounded-lg"
                                                                      clearable
                                                                      multiple
                                                            ></v-select>
                                                        </ValidationProvider>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12"  class="pb-0">
                                        <ValidationProvider ref="chronic_diseases" rules="min:1|max:65535"
                                                            v-slot="{ errors, valid }">
                                            <v-textarea v-model="chronic_diseases" type="text" :error-messages="errors"
                                                        :disabled="loading" :label="$t('chronic_diseases')" rows="5"
                                                        color="primary" auto-grow clearable outlined>
                                            </v-textarea>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12"  class="pt-0">
                                        <ValidationProvider ref="allergies" rules="min:1|max:65535"
                                                            v-slot="{ errors, valid }">
                                            <v-textarea v-model="allergies" type="text" :error-messages="errors"
                                                        :disabled="loading" :label="$t('allergies')" rows="5"
                                                        color="primary" auto-grow clearable outlined>
                                            </v-textarea>
                                        </ValidationProvider>
                                    </v-col>



                                </v-row>

                            </v-card-text>
                        </v-card>
                    </v-col>


                    <v-col cols="12"  class="pt-0">
                        <ValidationProvider ref="notes" rules="min:1|max:65535"
                                            v-slot="{ errors, valid }">
                            <v-textarea v-model="notes" type="text" :error-messages="errors"
                                        :disabled="loading" :label="$t('notes')" rows="5"
                                        color="primary" auto-grow clearable outlined>
                            </v-textarea>
                        </ValidationProvider>
                    </v-col>






                    <yandex-map class="py-0 my-0" v-if="coords && showMap" ref="map"
                                :coords="coords"
                                :controls = "[]"
                                :show-all-markers="showAllMarkers"
                                :zoom="zoom"
                                @map-was-initialized="initHandler"
                                style="background-color: #ffffff"
                    >
                    </yandex-map>

                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>

                    </v-col>
                </v-row>

                <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialogIin"
                    class="dialog_body"
                >
                    <v-card>
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('patient') }}</span>
                            </div>
                            <div>
                                <v-icon @click="closeIin()" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text class="pa-2 pb-0">
                            <v-col cols="12">
                                <ValidationProvider ref="iin" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="iin" type="text" :disabled="loading"
                                                  :error="!valid" :error-messages="errors"
                                                  :label="$t('iin')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="checkPatient()"
                            >{{ $t('check') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import {mask} from 'vue-the-mask'
import {loadYmap, yandexMap} from "vue-yandex-maps"
import $ from "jquery";

export default {
    name: 'EmergencyCallForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        yandexMap
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,

            phoneRaw: null,
            caller_phoneRaw: null,
            caller_name: null,
            iin: null,
            name: null,
            chronic_diseases: null,
            allergies: null,
            address: null,
            age: null,
            sex: null,
            sexItems: [],
            symptom_category: null,
            symptomCategoryItems: [],
            symptoms: [],
            symptomItems: [],
            city: null,
            cityItems: [],
            notes: null,


            point_x: window.innerWidth / 2 - 24,
            point_y: window.innerHeight / 2 - 48,
            ymaps: null,
            coordinates: [],
            showMap: false,
            showAllMarkers: false,
            mapMarker: null,
            hintMapContent: null,
            marker: null,
            zoom: 18,
            markerIcon: {
                preset: 'islands#redDotIcon',
                balloonShadow: false,
                balloonPanelMaxMapArea: 0,
                hideIconOnBalloonOpen: false,
                useMapMarginInDragging: true,
                layout: 'default#image',
                imageHref: '../img/marker.png',
                imageSize: [32, 32],
                imageOffset: [-32, -32],
                //imageOffset: [0, 0],
                //content: '123 v12',
                contentOffset: [-130, 20],
                //contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
            },
            myMap: null,
            geocoderMetaDataCash:{
                point: [],
                street: null,
                house: null,
            },
            street: null,
            house: null,
            entrance: null,
            floor: null,
            apartment: null,
            block: null,
            housing: null,
            zip: null,
            dialogIin: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        caller_phone: function () {
            return this.changePhone(this.caller_phoneRaw)
        },
        coords: {
            get: function () {
                return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [0, 0]
            },
            set: function (val) {
                this.coordinates = (val && val.length === 2) ? val : [0, 0]
            }
        },
        mapSettings() {
            if (this.language === 'en') {
                this.$yandex.lang = 'en_US'
            } else if (this.language === 'kk') {
                this.$yandex.lang = 'ru_RU'
            } else {
                this.$yandex.lang = 'ru_RU'
            }
            return this.$yandex
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getSexes()
        await this.getSymptomCategories()
        await this.getCities()
        await this.checkCreate()
        await this.ymapInit()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "emergency_call.create") {
                this.heading = this.$t('emergency_call_creation')
            } else {
                this.heading = this.$t('emergency_call_editing')
                if (this.$route.params.id) {
                    this.getEmergencyCall()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getEmergencyCall() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/emergency_call/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.name = res.body.data.name
                    this.caller_name = res.body.data.caller_name
                    this.caller_phoneRaw = res.body.data.caller_phone
                    this.city = res.body.data.city_id
                    this.symptom_category = res.body.data.symptom_category_id
                    this.symptoms = res.body.data.symptoms
                    this.symptomItems = res.body.data.symptoms
                    this.notes = res.body.data.notes
                    this.iin = res.body.data.patient.iin
                    this.age = res.body.data.patient.age
                    this.sex = res.body.data.patient.sex_id
                    this.phoneRaw = res.body.data.patient.phone
                    this.zip = res.body.data.patient.zip
                    this.street = res.body.data.patient.street
                    this.house = res.body.data.patient.house
                    this.entrance = res.body.data.patient.entrance
                    this.floor = res.body.data.patient.floor
                    this.apartment = res.body.data.patient.apartment
                    this.block = res.body.data.patient.block
                    this.housing = res.body.data.patient.housing
                    this.name = res.body.data.patient.name
                    this.chronic_diseases = res.body.data.patient.chronic_diseases
                    this.allergies = res.body.data.patient.allergies
                    this.coordinates = [res.body.data.patient.point[1], res.body.data.patient.point[0]]
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_emergency_call'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveEmergencyCall() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (this.caller_name) {
                formData.append('caller_name', this.caller_name)
            }
            if (this.caller_phoneRaw) {
                formData.append('caller_phone', this.caller_phoneRaw)
            }
            if (this.iin) {
                formData.append('iin', this.iin)
            }
            if (this.phoneRaw) {
                formData.append('phone', this.phoneRaw)
            }
            if (this.name) {
                formData.append('name', this.name)
            }
            if (this.sex) {
                if (this.sex.id) {
                    formData.append('sex_id', this.sex.id)
                } else {
                    formData.append('sex_id', this.sex)
                }
            }
            if (this.age) {
                formData.append('age', this.age)
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append('city_id', this.city.id)
                } else {
                    formData.append('city_id', this.city)
                }
            }
            if (this.street) {
                formData.append('street', this.street)
            }
            if (this.house) {
                formData.append('house', this.house)
            }
            if (this.entrance) {
                formData.append('entrance', this.entrance)
            }
            if (this.floor) {
                formData.append('floor', this.floor)
            }
            if (this.apartment) {
                formData.append('apartment', this.apartment)
            }
            if (this.block) {
                formData.append('block', this.block)
            }
            if (this.housing) {
                formData.append('housing', this.housing)
            }
            if (this.zip) {
                formData.append('zip', this.zip)
            }


            if (this.symptom_category) {
                if (this.symptom_category.id) {
                    formData.append('symptom_category_id', this.symptom_category.id)
                } else {
                    formData.append('symptom_category_id', this.symptom_category)
                }
            }

            if (this.symptoms) {
                formData.append('symptoms', this.symptoms)
            }

            if (this.chronic_diseases) {
                formData.append('chronic_diseases', this.chronic_diseases)
            }

            if (this.allergies) {
                formData.append('allergies', this.allergies)
            }

            if (this.notes) {
                formData.append('notes', this.notes)
            }

            if (this.coords && this.coords.length > 0) {
                for (let i in this.coords) {
                    formData.append(`point[${i}]`, this.coords[i])
                }
            }

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/emergency_call/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('emergency_call_has_been_updated'))
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('emergency_call_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/emergency_call', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('emergency_call_has_been_added'))
                        this.$router.push({
                            name: 'emergency_call.status',
                            params: {status: 'new'}
                        })
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('emergency_call_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getSexes() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/sex`, {
                    params: params,
                })
                .then(res => {
                    this.sexItems = res.body.data;
                })
                .catch(err => {
                    this.sexItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                })
        },
        async getSymptomCategories() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/symptom_category`, {
                    params: params,
                })
                .then(res => {
                    this.symptomCategoryItems = res.body.data;
                })
                .catch(err => {
                    this.symptomCategoryItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_symptom_categories'))
                })
        },
        async getSymptoms() {
            let params = {};
            params.filter = 'all'
            params.symptom_category_id = this.symptom_category
            await this.$http
                .get(`admin/symptom`, {
                    params: params,
                })
                .then(res => {
                    this.symptomItems = res.body.data;
                })
                .catch(err => {
                    this.symptomItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_symptoms'))
                })
        },
        async getCities() {
            let params = {};
            params.filter = 'all'
            await this.$http
                .get(`admin/city`, {
                    params: params,
                })
                .then(res => {
                    this.cityItems = res.body.data;
                })
                .catch(err => {
                    this.cityItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_cities'))
                })
        },
        async ymapInit() {
            var _this = this
            if (typeof (window.ymaps) !== "undefined") {
                if(window.ymaps){
                    this.showMap = false
                    if (this.$refs.map && this.$refs.map.myMap) {
                        this.$refs.map.myMap.destroy()
                    }
                    document.getElementById("vue-yandex-maps").remove()
                    delete window.ymaps
                }

            }

            await loadYmap({...this.mapSettings, debug: false})
            if (window.ymaps) {
                window.ymaps.ready(function () {
                    _this.showMap = false
                })
            }
        },
        initHandler(e) {
            var _this = this;
            if (this.coords && (this.coords.length !== 2 || (this.coords.length === 2 && this.coords[0] === 0 && window.ymaps)) ) {

                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: false,
                    autoReverseGeocode: true,
                })
                location.then(
                    async function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                        e.setCenter(result.geoObjects.position, 18, {duration: 300});
                        e.container.getElement().style.backgroundColor = 'white';
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }

            _this.myMap = e;

            _this.myMap.geoObjects.options.set('draggable', true)
            _this.myMap.geoObjects.options.set('openBalloonOnClick', false);

            _this.myMap.container.enterFullscreen();
            let mySearchControl = new window.ymaps.control.SearchControl({
                options:
                    {
                        float: 'right',
                        floatIndex: 100,
                        zoom: 18,
                        noPlacemark: true,
                        noPopup: true,
                        fitMaxWidth: true,
                        noSuggestPanel: true,
                        position: {
                            top: 10,
                            right: 55
                        }
                    }
            });
            _this.myMap.controls.add(mySearchControl);
            // Нажатие кнопки найти в
            mySearchControl.events.add('submit', function () {
                let query = mySearchControl.getRequestString();
                window.ymaps.geocode(query, {
                    // Указываем, что ответ должен быть в формате JSON.
                    json: true,
                    // Устанавливаем лимит на кол-во записей в ответе.
                    results: 1
                }).then(function (result) {
                    let point = result.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
                    let coords = point.split(" ")
                    _this.myMap.setCenter([coords[1],coords[0]],18);
                }, this);
            }, this);


            let
                CustomControlClass = function (options) {
                    CustomControlClass.superclass.constructor.call(this, options);
                    this._$content = null;
                    this._geocoderDeferred = null;
                };
            // И наследуем его от collection.Item.
            new window.ymaps.util.augment(CustomControlClass, window.ymaps.collection.Item, {
                onAddToMap: function (map) {
                    CustomControlClass.superclass.onAddToMap.call(this, map);
                    this._lastCenter = null;
                    this.getParent().getChildElement(this).then(this._onGetChildElement, this);
                },

                onRemoveFromMap: function (oldMap) {
                    this._lastCenter = null;
                    if (this._$content) {
                        this._$content.remove();
                        this._mapEventGroup.removeAll();
                    }
                    CustomControlClass.superclass.onRemoveFromMap.call(this, oldMap);
                },

                _onGetChildElement: function (parentDomContainer) {
                    // Создаем HTML-элемент с текстом.
                    this._$content =    $('<div class="customControl" style="font-weight: bold;font-size: 1.1em;position: fixed;position: fixed;text-align: center !important;width:100% ">Укажите адрес доставки</div>').appendTo(parentDomContainer);
                    this._mapEventGroup = this.getMap().events.group();
                    // Запрашиваем данные после изменения положения карты.
                    this._mapEventGroup.add('boundschange', this._createRequest, this);
                    // Сразу же запрашиваем название места.
                    this._createRequest();
                },

                _createRequest: function() {
                    var lastCenter = this._lastCenter = this.getMap().getCenter().join(',');
                    _this.geocoderMetaDataCash.point = this._lastCenter
                    // Запрашиваем информацию о месте по координатам центра карты.
                    window.ymaps.geocode(this._lastCenter, {
                        // Указываем, что ответ должен быть в формате JSON.
                        json: true,
                        // Устанавливаем лимит на кол-во записей в ответе.
                        results: 1
                    }).then(function (result) {
                        // Будем обрабатывать только ответ от последнего запроса.
                        if (lastCenter === this._lastCenter) {
                            this._onServerResponse(result);
                        }
                    }, this);
                },

                _onServerResponse: function (result) {
                    // Данные от сервера были получены и теперь их необходимо отобразить.
                    // Описание ответа в формате JSON.
                    var members = result.GeoObjectCollection.featureMember,
                        geoObjectData = (members && members.length) ? members[0].GeoObject : null;
                    if (geoObjectData) {
                        //Добавляем текст на карте сверху
                        //console.log(geoObjectData.metaDataProperty.GeocoderMetaData.text);
                        this._$content.text(geoObjectData.metaDataProperty.GeocoderMetaData.text);
                        //Доавляем данные в переменные
                        let GeocoderMetaData = geoObjectData.metaDataProperty.GeocoderMetaData
                        if (GeocoderMetaData.Address.Components) {
                            let Components = GeocoderMetaData.Address.Components
                            let street_full = []
                            let district = Components.filter(item => item.kind === 'district')
                            if (district.length > 0) {
                                street_full.push(district[0].name)
                            }
                            let street = Components.filter(item => item.kind === 'street')
                            if (street.length > 0) {
                                street_full.push(street[0].name)
                            }
                            let house = Components.filter(item => item.kind === 'house')
                            if (house.length > 0) {
                                _this.geocoderMetaDataCash.house = house[0].name
                            }
                            _this.geocoderMetaDataCash.street = street_full.join(', ')
                        }

                    }
                }
            });

            var customControl = new CustomControlClass();
            _this.myMap.controls.add(customControl, {
                float: 'none',
                position: {
                    top: 50
                }
            });


            // Создаем курсор по центру
            let ButtonLayoutCursor = window.ymaps.templateLayoutFactory.createClass([
                    '<div class="my-button">',
                    '<img class="my-button__img" src="{{ data.image }}" />',
                    '</div>'
                ].join('')),

                buttonCursor = new window.ymaps.control.Button({
                    data: {
                        image: '../../img/marker.png',
                    },
                    options: {
                        layout: ButtonLayoutCursor,
                        maxWidth: [170, 190, 220]
                    }
                });

            _this.myMap.controls.add(buttonCursor, {

                float: 'none',
                position: {
                    top: this.point_y,
                    left: this.point_x
                }
            });


            //--- создание кнопка закрыть вверху слево
            let ButtonLayoutClose = window.ymaps.templateLayoutFactory.createClass([
                    '<div style="background-color:#ffdb4d;padding:4px 5px 3px 5px;" title="{{ data.title }}" class="my-button small">',
                    '<img  class="my-button__img__close" src="{{ data.image }}" alt="X">',
                    '</div>'
                ].join('')),

                buttonClose = new window.ymaps.control.Button({
                    data: {
                        image: '../../img/close.svg',
                        title: "Закрыть"
                    },
                    options: {
                        layout: ButtonLayoutClose,
                        size: 'small',
                    }
                });

            _this.myMap.controls.add(buttonClose, {
                float: 'none',
                position: {
                    top: 10,
                    right: 20

                }
            });

            buttonClose.events.add('click', function () {
                _this.showMapClose()

            });



            // ----------- Создание кнопки Готово снизу  -----------------------
            let ButtonLayout = window.ymaps.templateLayoutFactory.createClass([
                    '<div title="{{ data.title }}" class="my-button" style="position: fixed;width: 100%;text-align: center;">',
                    '<button class="my-button__text" style="background-color: #E44812;font-size: 1.2em;padding:0.7em 1em;border: solid 1px #E0E4E6;font-weight: bold;color:#fff;">{{ data.content }}</button>',
                    '</div>'
                ].join('')),

                buttonOK = new window.ymaps.control.Button({
                    data: {
                        content: this.$t('done'),
                        title: this.$t('done')
                    },
                    options: {
                        layout: ButtonLayout,

                    }
                });

            _this.myMap.controls.add(buttonOK, {
                float: 'none',
                position: {
                    bottom: 100
                }
            });

            buttonOK.events.add('click', function (e) {
                _this.MapButtonOK()
            });





        },
        openMap(){
            this.showMap = true;
        },
        onBoundsChange(e) {
            var _this = this
            this.coords = e.get('newCenter')
            var myGeocoder = window.ymaps.geocode(this.coords)
            myGeocoder.then(
                function (res) {
                    let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                    if (GeocoderMetaData.Address.Components) {
                        let Components = GeocoderMetaData.Address.Components
                        let street_full = []
                        let district = Components.filter(item => item.kind === 'district')
                        if (district.length > 0) {
                            street_full.push(district[0].name)
                        }
                        let street = Components.filter(item => item.kind === 'street')
                        if (street.length > 0) {
                            street_full.push(street[0].name)
                        }
                        let house = Components.filter(item => item.kind === 'house')
                        if (house.length > 0) {
                            _this.house = house[0].name
                        }
                        _this.street = street_full.join(', ')
                    }
                },
                function (err) {
                    console.log('Error Load GeocoderMetaData')
                }
            )
        },
        showMapClose(){
            this.myMap.destroy();
            this.myMap = null;
            this.showMap = false;
        },
        MapButtonOK(){
            this.coords = this.geocoderMetaDataCash.point.split(',');
            this.street = this.geocoderMetaDataCash.street;
            this.house = this.geocoderMetaDataCash.house;
            this.myMap.destroy();
            this.myMap = null;
            this.showMap = false;
        },
        setCoordinate(){
            let city = this.cityItems.find(item => item.id === this.city);
            this.coordinates = city.point;
            this.markerIcon.contentOffset = city.point;
        },
        closeIin(){
            this.dialogIin = false;
        },
        async checkPatient() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.iin = this.iin
            await this.$http
                .get(`admin/patient/0`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.city = res.body.data.city_id
                    this.iin = res.body.data.iin
                    this.age = res.body.data.age
                    this.sex = res.body.data.sex_id
                    this.phoneRaw = res.body.data.phone
                    this.zip = res.body.data.zip
                    this.street = res.body.data.street
                    this.house = res.body.data.house
                    this.entrance = res.body.data.entrance
                    this.floor = res.body.data.floor
                    this.apartment = res.body.data.apartment
                    this.block = res.body.data.block
                    this.housing = res.body.data.housing
                    this.name = res.body.data.name
                    this.chronic_diseases = res.body.data.chronic_diseases
                    this.allergies = res.body.data.allergies
                    this.coordinates = [res.body.data.point[0], res.body.data.point[1]]
                    this.closeIin();
                })
                .catch(err => {
                    this.closeIin();
                    this.$toastr.error(this.$t('failed_to_get_patient'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
    }
}
</script>
